import { Grid, Box, Heading } from "@chakra-ui/react";
import { SocketContext } from "../Contexts/Context";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const VideoPlayerSerius = () => {
  const { name, callAccepted, myVideo, userVideo, callEnded, stream, call, myVideoStream, userVideoStream, dataContext } = useContext(SocketContext);
  const [loading, set_loading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      set_loading(false);
    }, 3000);
  }, []);


//   if (loading || !stream) {
//     return (
//       <div className="flex justify-content-center align-items-center" style={{ height: '100%' }}>
//         <div>Loading...</div>
//       </div>
//     );
//   }

return (
  <div className="row">
      <div className="col-md-6">
      {
          stream && (
              <>
                  <Heading as="h5">
                      {name || 'Name'}
                  </Heading>
                  <video playsInline muted ref={myVideo} autoPlay width="600" />
              </>
          )
      }
      </div>
      <div className="col-md-6">
          {
              callAccepted && !callEnded && (
                  <>
                      <Heading as="h5">
                          {call.name || dataContext.userToCall.name}
                      </Heading>
                      <video playsInline ref={userVideo} autoPlay width="600" />
                  </>
              )
          }
      </div>
  </div>
  
)

  return (
    <div className="">
      {stream && (
        <div className="align-items-center">
          {callAccepted && !callEnded ? (
            <>
              <h5>On Calling {call.name || dataContext.userToCall.name}</h5>
              {userVideo ? (
                <div className="row">
                  <div className="col-md-6">
                      <video playsInline ref={userVideo} autoPlay
                        style={{
                          width: '100%',
                          height: 'auto',
                        }}
                      />
                  </div>
                  <div className="col-md-6">
                    <video playsInline ref={myVideo} autoPlay
                      className="video"
                      style={{
                        width: '100%',
                        height: 'auto',
                      }}
                    />
                  </div>
                </div>
              ) : (
                <div>{call.name} video not working</div>
              )}
            </>
          ) : (
            <>
              <h5>{name || 'Name'}</h5>

              {myVideo ? (
                <video playsInline muted ref={myVideo} autoPlay
                  className="video"
                  style={{
                    width: '80%',
                    height: 'auto',
                  }}
                />
              ) : (
                <div>{name} video not working</div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default VideoPlayerSerius;
