import React, { useContext, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { AppEnv } from '../constants/AppEnv';
import { SocketContext } from '../Contexts/Context';
import { useLocalStorage } from '../hooks/useLocalStorage';

export default function IsVerified() {
    let { token, username } = useParams();
    const navigate = useNavigate();
    const [user, setUser] = useLocalStorage("user", null);
    
    useEffect( () => {

        checkToken();
    }, [])

    const checkToken = async () => {
        const response = await fetch(AppEnv.APP_URL_SOCKET+'verify-token?token='+token, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        console.log('response', response)
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const jsonRes = await response.json();
        if ( jsonRes.code == '200' ) {
            setUser({
                user_name: username,
                company: jsonRes.token.company,
            })
            setTimeout(() => {
                navigate('/app')
            }, 1000);
            console.log('json',jsonRes)
        }
      };
    
    
    return (
        <div>IsVerified</div>
    )
}
