import React, { useContext } from 'react';
import logo from '../logo.svg';
import '../App.css';

import { Box, Heading, Container } from '@chakra-ui/react';
import Notifications from '../components/Notifications';
import Options from '../components/Options';
import VideoPlayer from '../components/VideoPlayer';
import { useParams } from 'react-router-dom';
import VideoPlayerSerius from '../components/VideoPlayerSerius.js';
import OptionsSerius from '../components/OptionsSerius.js';
import NotificationsSerius from '../components/NotificationsSerius.js';

function CallModeSerius() {

  return (
    <Box>
      <Container maxW="1200px" mt="8">
        {/* <Heading as="h2" size="2xl"> Video Chat App </Heading> */}
        <VideoPlayerSerius />
        <OptionsSerius />
        <NotificationsSerius />
      </Container>
    </Box>
  )
}


export default CallModeSerius;
