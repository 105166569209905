import React, { useContext } from "react";

import { Navigate, Outlet } from "react-router-dom";
import { SocketContext } from "../Contexts/Context";

const AuthMiddleware = () => {
	const { masuk, setName } = useContext(SocketContext);
	// TODO: Use authentication token
	const user = localStorage.getItem("user");

	if ( user ) {
		// setName(user.user_name);
		// console.log('user authmiddleware', user)
		// masuk()
	}

	return user ? <Outlet /> : <Navigate to="/"  replace />;
};

export default AuthMiddleware;