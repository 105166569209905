import React, { useContext, useEffect } from 'react'

import { SocketContext } from '../Contexts/Context';
import { Button, FormLabel, Input } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { AppEnv } from '../constants/AppEnv';

export function MyNavigate(path) {
  window.location.href = path;
}
function Welcome() {
  
    const { me, callAccepted, name, setName, callEnded, leaveCall, callUser, masuk } = useContext(SocketContext);
    const navigate = useNavigate();
    const [user, setUser] = useLocalStorage("user", null);

    useEffect( () => {
      // checkAPI();
    },[])

    const checkAPI = async () => {
      const response = await fetch(AppEnv.APP_URL_SOCKET, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      console.log('response', response)
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      
    };

  return (
    <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh', textAlign: 'center' }}>
        <div>
            <h1>Welcome</h1>
            <FormLabel>Username</FormLabel>
            <Input marginBottom={5} type='text' value={name} onChange={(e) => setName(e.target.value)} width="100%" />
            <button className='btn btn-primary' type='button' onClick={() => {
                setUser({
                  user_name: name
                })
                masuk();
                setTimeout(() => {
                  navigate('/app')
                }, 1000);
            }}>Masuk</button>
        </div>
    </div>
  )
}

export default Welcome