import React, { useContext, useEffect } from 'react'
import { SocketContext } from '../Contexts/Context';
import { useNavigate } from 'react-router-dom';
import { MyNavigate } from '../services/GlobalFunctions';

export default function UsersOnline() {
const navigate = useNavigate();
  const { userOnline, dataContext, setDataContext } = useContext(SocketContext);
  const handleCall = (callId, user_name) => {
    setDataContext((prevData) => ({
      ...prevData,
      userToCall: {
        call_id: callId,
        name: user_name,
      }
    }))
    // navigate('/call/'+callId,);
    navigate('/call',);
  }

  useEffect( () => {
    console.log('user online changed')
  }, [userOnline])
  return (
    <>
        <h3>User Online {userOnline.length}</h3>
        <div style={{ maxHeight: 250, overflowY: 'scroll', overflow: 'hidden'}}>
            {
                userOnline.map( (v,i) => {
                return (
                    <>
                        <li key={i}>{v.user_name}<b onClick={() => {
                            handleCall(v.conn_id, v.user_name)
                        }}> <i class="fa-solid fa-phone"></i></b></li>
                    </>
                )
                })
            }
        </div>
    </>
  )
}
