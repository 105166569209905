
export default function LoadingPage() {

  return (
    <div id="error-page" className="d-flex justify-content-center align-items-center" style={{ height: '100vh', textAlign: 'center' }}>
    <div>
      <h1>Loading...</h1>
      <p>wait a minute...</p>
    </div>
  </div>
  );
}