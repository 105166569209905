import { createContext, useState, useRef, useEffect } from 'react';
import { io } from 'socket.io-client';
import Peer from 'simple-peer';
import { AppEnv } from '../constants/AppEnv';

const SocketContext = createContext();
const socket = io(AppEnv.APP_URL_SOCKET);
const ContextProvider = ({ children }) => {
    const [callAccepted, setCallAccepted] = useState(false);
    const [callEnded, setCallEnded] = useState(false);
    const [stream, setStream] = useState();
    const [userOnline, setUserOnline] = useState([]);
    const [name, setName] = useState('');
    const [call, setCall] = useState({});
    const [me, setMe] = useState('');
    const myVideo = useRef({});
    const [myVideoStream, setMyVideoStream] = useState('');
    const userVideo = useRef();
    const [userVideoStream, setUserVideoStream] = useState('');
    const connectionRef = useRef();
    const [isCameraOn, setIsCameraOn] = useState(true);
    const [otherUserCameraOn, setOtherUserCameraOn] = useState(true);
    const [loading, setLoading] = useState(true);
    const [userMasuk, setUserMasuk] = useState(null);
    const [dataContext, setDataContext] = useState({
        userToCall: {},
    });
    
    useEffect(() => {
       
        // startDeviceVideo();
        socket.on("notifFront", (data) => {
            alert(data.message);
        })
        
        socket.on('me', (id) => {
            console.log('me id', id)
            setMe(id)
            setTimeout(() => {
                setLoading(false)
            }, 1000);
        });
        socket.on('callUser', ({ from, name: callerName, signal, onCallingData }) => {
            console.log('onCallingData', onCallingData);
            setCall({ isReceivingCall: true, from, name: callerName, signal, onCallingData });
        });
        socket.on("userOnline", (data) => {
            // console.log('userOnline', data);
            // alert(JSON.stringify(data))
            setUserOnline(data);
        })

        socket.on('toggleCamera', ({ isCameraOn }) => {
            setOtherUserCameraOn(isCameraOn);
            if (isCameraOn) {
                // Start or update the video stream
                // For example:
                // const newStream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
                // userVideo.current.srcObject = newStream;
            } else {
                // Stop the video stream
                // For example:
                // userVideo.current.srcObject.getTracks().forEach((track) => track.stop());
                // userVideo.current.srcObject = null; // Set to null or an empty stream
            }
        });

        socket.on('leaveCallFront', (data) => {
            console.log('leaveCallFront data: ', data.socketId);
            setCallEnded(true);
            connectionRef.current.destroy();
            window.location.reload();
        });
    }, []);
    
    const answerCall = () => {
        console.log('call.onCallingData',call.onCallingData)
        setCallAccepted(true);
        const peer = new Peer({ initiator: false, trickle: false, stream });
        peer.on('signal', (data) => {
            socket.emit('answerCall', { signal: data, to: call.from, onCallingData: call.onCallingData, currentUserData: { socketId: me, user_name: name} });
        });
        peer.on('stream', (currentStream) => {
            userVideo.current.srcObject = currentStream;
            userVideo.current.style.transform = 'scaleX(-1)';
            setUserVideoStream(currentStream);
            console.log('currentStream',currentStream)
            console.log(' answerCall userVideo.current',userVideo.current)
        });
        peer.signal(call.signal);
        connectionRef.current = peer;
    };
    
    const callUser = (id,userToCallName = null) => {
        console.log('callUser : ',{ userToCall: id, userToCallName: userToCallName, from: me, name })
        const peer = new Peer({ initiator: true, trickle: false, stream });
        peer.on('signal', (data) => {
                socket.emit('callUser', { userToCall: id,userToCallName, signalData: data, from: me, name });
        });
        peer.on('stream', (currentStream) => {
            userVideo.current.srcObject = currentStream;
            userVideo.current.style.transform = 'scaleX(-1)';
            setUserVideoStream(currentStream);
            console.log('currentStream',currentStream)
            console.log(' callUser userVideo.current',userVideo.current)
        });
        socket.on('callAccepted', (signal) => {
            setCallAccepted(true);
            peer.signal(signal);
        });
       
        connectionRef.current = peer;
    };
    
    const leaveCall = () => {
        socket.emit('leaveCall', { socketId: me, user_name: name});
        
    };

    const masuk = (data) => {
        if ( me ) {
            if ( data ) {
                const emit_data = {
                    user_conn_id: me,
                    user_name: data.user.user_name,
                    company: data.user?.company ?? '',
                }
                if ( !userMasuk ) {
                    socket.emit('masuk', emit_data) 
                    setUserMasuk(emit_data)
                }
            } else {
                const emit_data =  {
                    user_conn_id: me,
                    user_name: name,
                }
                if ( !userMasuk ) {
                    socket.emit('masuk',emit_data) 
                    setUserMasuk(emit_data)
                }
            }
        }

    }

    const stopMyVideo = () => {
        if (myVideo.current) {
            setIsCameraOn(false);
          myVideo.current.pause();
        }
    };

    const startMyVideo = () => {
        if (myVideo.current) {
            setIsCameraOn(true);
            myVideo.current.play();
        }
    };

    const startDeviceVideo = () => {
        navigator.mediaDevices.getUserMedia({ video: true, audio: true })
        .then((currentStream) => {
            setStream(currentStream);
            console.log('currentStream',currentStream)
            console.log('myVideo.current',myVideo.current)
            setMyVideoStream(currentStream);
            myVideo.current.srcObject = currentStream;
                if ( myVideo.current.style ) {
                    myVideo.current.style.transform = 'scaleX(-1)';
                }
        });
    }

    const toggleCamera = async () => {
        if (myVideo.current) {
            if (isCameraOn) {
              // Turn off the camera
              myVideo.current.srcObject.getTracks().forEach((track) => track.stop());
                setIsCameraOn(false);
            } else {
              // Turn on the camera
              try {
                const newStream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
                myVideo.current.srcObject = newStream;
                setIsCameraOn(true);
              } catch (error) {
                console.error('Error accessing camera:', error);
              }
            }
        }
    };
    
    return (
        <SocketContext.Provider value={{
            loading, setLoading,
            dataContext, setDataContext,
            call,
            callAccepted,
            startDeviceVideo,
            myVideo,
            userVideo,
            stream,
            name,
            setName,
            callEnded,
            me,
            callUser,
            leaveCall,
            answerCall,
            masuk,
            userOnline,
            myVideoStream,
            userVideoStream,
            isCameraOn,
            stopMyVideo,
            startMyVideo,
            toggleCamera
        }}
        >
            {children}
        </SocketContext.Provider>
    );
};
export { ContextProvider, SocketContext };
