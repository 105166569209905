import React, { useEffect, useRef, useState } from 'react';
import io from 'socket.io-client';
import SimplePeer from 'simple-peer';
import { AppEnv } from '../constants/AppEnv';

const socket = io(AppEnv.APP_URL_SOCKET);

const MyAppV4 = () => {
  const [roomId, setRoomId] = useState('');
  const [stream, setStream] = useState(null);
  const [peers, setPeers] = useState([]);
  const [isJoined, setIsJoined] = useState(false);
  const [userName, setUserName] = useState('');
  const videoRef = useRef();

  useEffect(() => {
    navigator.mediaDevices.getUserMedia({ video: true, audio: true })
      .then((stream) => {
        setStream(stream);
        videoRef.current.srcObject = stream;
      })
      .catch((error) => console.error('Error accessing media devices:', error));

    socket.on('userJoined', ({ userId, userName }) => {
      console.log('userJoined userId:', userId);
      setIsJoined(true);
      if (!peers.find(peer => peer.userId === userId)) { 
        const peer = createPeer(userId, socket.id, stream);
        setPeers((prevPeers) => [...prevPeers, { userId, peer, userName }]);
      }
    });

    socket.on('offer', (offer, senderId) => {
      if (!peers.find(peer => peer.userId === senderId)) { 
        const peer = addPeer(offer, senderId, stream);
        setPeers((prevPeers) => [...prevPeers, { userId: senderId, peer }]);
      }
    });

    socket.on('answer', (answer, receiverId) => {
      const peer = peers.find((p) => p.userId === receiverId)?.peer;
      if (peer) {
        peer.signal(answer);
      }
    });

    socket.on('iceCandidate', (candidate, userId) => {
      console.log('iceCandidate peers:', peers);
      const peer = peers.find((p) => p.userId === userId)?.peer;
      if (peer) {
        peer.addIceCandidate(candidate);
      }
    });

    socket.on('roomCreated', (roomId) => {
      setRoomId(roomId);
    });

    socket.on('joinedRoom', () => {
      setIsJoined(true);
    });

    socket.on('userLeft', ({userId, userName}) => {
        console.log('userLeft', userId, userName)
        const peersLeft = peers.filter(peer => peer.userId === userId);
        console.log('peersLeft',peersLeft)
        setPeers((prevPeers) => prevPeers.filter(peer => peer.userId !== userId));
        alert(userName+' has been left room');
    });

    console.log('peers', peers);

    return () => {
      // Membersihkan listener saat komponen dibongkar
      socket.off('userJoined');
      socket.off('offer');
      socket.off('answer');
      socket.off('iceCandidate');
      socket.off('roomCreated');
      socket.off('joinedRoom');
      socket.off('userLeft');
    };
  }, [peers]);

  const createRoom = () => {
    socket.emit('createRoom');
  };

  const leaveRoom = () => {
    console.log('roomId', roomId, userName);
    socket.emit('leaveRoom', { roomId, userName});
    setPeers([]);
    setIsJoined(false);
  };

  const joinRoom = () => {
    if (roomId) {
      socket.emit('joinRoom', { roomId, userName });
    }
  };

  const startVideoChat = () => {
    if (roomId) {
      socket.emit('joinRoom', { roomId, userName });
    }
  };

  const createPeer = (receiverId, senderId, stream) => {
    const peer = new SimplePeer({
      initiator: true,
      trickle: false,
      stream,
    });

    peer.on('signal', (offer) => {
      socket.emit('offer', offer, receiverId, userName); // Menambahkan userName ke offer
    });

    peer.on('connect', () => {
      console.log('Peer connected');
    });

    return peer;
  };


  const addPeer = (offer, senderId, stream) => {
    const peer = new SimplePeer({
      initiator: false,
      trickle: false,
      stream,
    });

    peer.on('signal', (answer) => {
      socket.emit('answer', answer, senderId);
    });

    peer.on('connect', () => {
      console.log('Peer connected');
    });

    peer.signal(offer);

    return peer;
  };


  return (
    <div>
      <button onClick={createRoom}>Create Room</button>
      <input type="text" value={roomId} onChange={(e) => setRoomId(e.target.value)} placeholder="Enter Room ID" />
      {!isJoined ? (
        <div>
          <input type="text" value={userName} onChange={(e) => setUserName(e.target.value)} placeholder="Enter Your Name" />
          <button onClick={startVideoChat}>Start Video Chat</button>
          <video ref={videoRef} autoPlay muted />
        </div>
      ) : (
        <>
            <button onClick={leaveRoom}>Leave Room</button>
            {peers.map((peer) => (
            <div key={peer.userId}>
                <video autoPlay ref={(ref) => {
                if (ref && !peer.videoRef) {
                    peer.videoRef = ref;
                    ref.srcObject = stream;
                }
                }} />
                <p>{peer.userName}</p> {/* Menampilkan nama pengguna di atas video */}
            </div>
            ))}
        </>
      )}
      <div>
       

      </div>
    </div>
  );
};

export default MyAppV4;
