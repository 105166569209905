import React, { useContext, useEffect } from 'react';
import logo from '../logo.svg';
import '../App.css';

import { Box, Heading, Container } from '@chakra-ui/react';
import Notifications from '../components/Notifications';
import Options from '../components/Options';
import VideoPlayer from '../components/VideoPlayer';
import { SocketContext } from '../Contexts/Context';
import { Navigate, useNavigate } from 'react-router-dom';
import { useLocalStorage } from '../hooks/useLocalStorage';
import LoadingPage from './LoadingPage';
import UsersOnline from '../components/UsersOnline';

function Dashboard() {
  const { myVideo } = useContext(SocketContext)
  const [user, setUser] = useLocalStorage('user',null);
    const {loading, answerCall, call, callAccepted, masuk , name, setName, me } = useContext(SocketContext);

  useEffect( () => {
   
  },[])

  useEffect( () => {
    if (!loading ) {
      setName(user.user_name)
      setTimeout(() => {
        masuk({
          user: user
        })
      }, 1000);
    }
  },[loading])

  if ( loading ) {
    return <LoadingPage />
  }

  

  return (
    <div className='container py-5'>
      {call.isReceivingCall && !callAccepted ? 
          <>
            <Navigate to={"/call/"+call.userToCall}  replace />
          </>
        :
        <div className='row justify-content-center'>
          <div className='col-md-8 mb-5'>
            <span>Halo, <h3>{name}</h3></span>
            <div>
              <video playsInline muted ref={myVideo} autoPlay width="600" />
              <div></div>
            </div>
          </div>
          <div className='col mb-5'>
            <UsersOnline />
          </div>
        </div>
      }
    </div>
  )
}

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

export default Dashboard;
